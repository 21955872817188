import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import CRORightRoundOne from "../images/CRORightRoundOne.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import Shapefour from "../images/cro-banner.svg"
import ABandMultivariateTesting from "../images/icons/CRO/ABandMultivariateTesting.svg"
import AnalyzeOptimizeandRepeat from "../images/icons/CRO/AnalyzeOptimizeandRepeat.svg"
import CROAudit from "../images/icons/CRO/CROAudit.svg"
import CROAuditsandStrategyDevelopment from "../images/icons/CRO/CROAuditsandStrategyDevelopment.svg"
import DesignandPrioritizTestPlans from "../images/icons/CRO/DesignandPrioritizTestPlans.svg"
import IdentifyConversionBlockers from "../images/icons/CRO/IdentifyConversionBlockers.svg"
import ImplementAB from "../images/icons/CRO/ImplementABandMultivariateTests.svg"
import LandingPageOptimization from "../images/icons/CRO/LandingPageOptimization.svg"
import PersonalizationTargeting from "../images/icons/CRO/PersonalizationandBehavioralTargeting.svg"
import ShoppingCartOptimization from "../images/icons/CRO/ShoppingCartAndCheckoutOptimization.svg"
import image1 from "../images/image-one.svg"
import image3 from "../images/image-three.svg"
import image2 from "../images/image-two.svg"
import "../styles/404.css"

const CreativeAssets = ({ siteTitle }) => {
  const [countUpVisible, setCountUpVisible] = useState(false)
  const handleVisibilityChange = isVisible => {
    if (isVisible) {
      setCountUpVisible(true)
    }
  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>CRO | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="cro digital-consulting">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Unlock your revenue growth with CRO</h1>
                    <p>
                      Let our experts tweak website pages for higher conversion
                      potential.
                    </p>
                    <a className="button" id="Get-in-touch" href="/contact-us/">
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>
                Boost revenue, not just traffic: drive measurable results with
                CRO
              </h2>
              <p>
                We enhance your CRO strategy by utilizing Artificial
                Intelligence (AI), pinpointing areas of visitor attrition, and
                instituting data-driven modifications. Our approach transforms
                more visitors into dedicated customers by uncovering hidden
                conversion barriers across your digital touchpoints.
              </p>
            </div>
          </section>
          <section id="WeDeliver">
            <div class="container">
              <h2>Our capabilities</h2>
              <div class="horizontalImageIconSec">
                <div class="moduleWrap">
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={CROAuditsandStrategyDevelopment}
                      />
                    </div>
                    <div class="contents">
                      <h3>CRO Audits and Strategy Development</h3>
                      <p>
                        Comprehensive performance audits assessing current
                        conversion benchmarks and determining focus areas
                        aligned with business goals.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={LandingPageOptimization}
                      />
                    </div>
                    <div class="contents">
                      <h3>Landing Page Optimization</h3>
                      <p>
                        Refine page copy, layouts, visuals and calls-to-action
                        to increase conversion potential.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={ABandMultivariateTesting}
                      />
                    </div>
                    <div class="contents">
                      <h3>A/B and Multivariate Testing</h3>
                      <p>
                        Design, build and analyze conversion-focused tests using
                        our advanced testing framework.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={ShoppingCartOptimization}
                      />
                    </div>
                    <div class="contents">
                      <h3>Shopping Cart and Checkout Optimization</h3>
                      <p>
                        Identify and resolve funnel friction points to reduce
                        cart abandonment.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={PersonalizationTargeting}
                      />
                    </div>
                    <div class="contents">
                      <h3>Personalization and Behavioral Targeting</h3>
                      <p>
                        Customize experiences for traffic segments using machine
                        learning algorithms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Conquer your conversion challenges</h2>
                  <p>
                    We directly tackle three key challenges that digital
                    executives are currently dealing with:
                  </p>
                  <p>
                    <strong>Increasing conversion rates:</strong>&nbsp;Increase
                    online &amp; offline conversions by 25-30%+ with our CRO
                    solutions.
                  </p>
                  <p>
                    <strong>Improving customer experience:&nbsp;</strong>
                    Identify &amp; resolve UX issues with session replays &amp;
                    heatmaps.
                  </p>
                  <p>
                    <strong>Adapt and thrive:</strong>&nbsp;Future-proof
                    experiences with our experimentation &amp; personalization
                    expertise.
                  </p>
                </div>
                <div class="img-wrap">
                  <img
                    alt="Copy write"
                    width="500"
                    height="500"
                    src={CRORightRoundOne}
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="Highlights">
            <div class="container">
              <div class="wrapper">
                <h2>Highlighting AI-driven success</h2>
                <p>
                  Our ML algorithms analyze large datasets of user behavior,
                  identify conversion bottlenecks, and predict user actions to
                  inform A/B testing and personalization strategies. We use
                  best-in-class third-party AI-powered testing tools to automate
                  A/B testing at scale, run more sophisticated tests,&nbsp;and
                  analyze results for faster optimization. Our automated
                  reporting ensures detailed reports on CRO campaign
                  performance,&nbsp;identifies trends&nbsp;and measures ROI
                  accurately.
                </p>
              </div>
            </div>
          </section>
          <section id="CaseStudy">
            <div class="sliderwrap">
              <div class="contentholder">
                <div class="container">
                  <div class="descp">
                    <p class="title">Case Study</p>
                    <div>
                      <h2>Creating winning landing page</h2>
                      <p>
                        For American Express, we crafted innovative landing page
                        which led to a striking ~45% increase in brand
                        awareness. We fine-tuned user experience and also
                        introduced a mobile-friendly interactive page,
                        simplifying navigation and enhancing user engagement.
                      </p>
                    </div>
                    <a
                      className="button"
                      href="https://docs.google.com/presentation/d/1FSTcKpHNF55OfxnA7Cbzp4Uo6p6ll2_UsaN8NYqpHDQ/edit#slide=id.g2bdfcd5443c_0_370"
                      target="_blank"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="Process">
            <div class="container">
              <h2>Our process</h2>
              <p>
                Our CRO process begins with an audit to analyze site metrics,
                evaluate performance, and compare to competitors. We identify
                conversion blockers, focusing on key areas aligned with business
                KPIs. Next, we design and prioritize test plans for high-traffic
                pages, implementing A/B and multivariate tests to find optimized
                versions. We then analyze results, roll out winning variants
                site-wide, and continuously test and optimize for better
                performance.
              </p>
              <div class="multiStageProcess">
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={CROAudit} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image1} />
                    </span>
                  </div>
                  <div class="processContent">CRO audit</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img
                      width="100"
                      height="100"
                      src={IdentifyConversionBlockers}
                    />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Identify conversion blockers</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img
                      width="100"
                      height="100"
                      src={DesignandPrioritizTestPlans}
                    />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">
                    Design and prioritize test plans
                  </div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={ImplementAB} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">
                    Implement A/B and multivariate tests
                  </div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img
                      width="100"
                      height="100"
                      src={AnalyzeOptimizeandRepeat}
                    />
                    <span class="borderImg">
                      <img width="160" height="161" src={image3} />
                    </span>
                  </div>
                  <div class="processContent">
                    Analyze, optimize, and repeat
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>
                Ready to unlock your revenue potential with data-driven CRO?
              </h2>
              <a className="button" id="Get-in-touch" href="/contact-us/">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default CreativeAssets
